.navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #00304a;

  color: #F7F7FF;
  position: sticky;
  top: 0;
  z-index: 1000;

  .logo {
    font-size: 1.5rem;
    font-weight: bold;

    a {
      color: #fff;
      text-decoration: none;

      .smaller {
        font-size: 0.8rem;
        font-weight: normal;
      }
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 1rem;

      a {
        text-decoration: none;
        color: #fff;
        font-size: 1rem;
        transition: color 0.3s;

        &:hover {
          color: #f4e04d;
        }
      }
    }
  }

  .menu {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #fff;

    i {
      pointer-events: none;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 900;
  }

  // Mobile styles
  @media screen and (max-width: 768px) {
    .menu {
      display: block;
    }

    ul {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 100%;
      right: 0;
      background-color: #333;
      width: 100%;
      padding: 1rem 0;

      li {
        margin: 0.5rem 0;
        text-align: center;
      }
    }

    ul.open {
      display: flex;
    }
  }
}
