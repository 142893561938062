.past-events-page {
  .fade-up {
    opacity: 0;
    transform: translateY(30px);
    animation: fadeUp 0.8s ease-in forwards;
  }

  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  margin-bottom: 3rem;
  color: white;
  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    background: url("https://images.pexels.com/photos/6321231/pexels-photo-6321231.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")
      no-repeat center center/cover;
    color: #fff;
    text-align: center;

    .hero-content {
      background: rgba(
        0,
        0,
        0,
        0.5
      ); // Add a slight overlay for better text readability
      padding: 20px;
      border-radius: 10px;

      h1 {
        font-size: 3rem;
        margin: 0;
      }

      .caveat {
        font-size: 1.2rem;
        margin-top: 10px;
      }
    }
  }

  .events-header {
    text-align: center;
    margin: 50px 20px;

    h1 {
      font-size: 2.5rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2rem;
      max-width: 800px;
      margin: 0 auto;
    }
  }

  .event-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;

    .event-card {
      &:hover {
        transform: translateY(-10px);
      }
      transition: all 0.2s ease-out;
      display: flex;
      // align-items: center;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      @media (max-width: 768px) {
        flex-direction: column;
      }

      img {
        width: 400px;
        height: 400px;
        object-fit: cover;
      @media (max-width: 768px) {
        width: 100%;
      }}
      .event-info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: start;
        p {
          font-size: 1.3rem;
        }
      }

      h2 {
        margin: 0;
        font-size: 1.5rem;
        color: #000000;
      }

      p {
        margin: 5px 0;
        color: #000000;
      }

      .register-button {
        background: #007bff;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background 0.3s;
        margin: 0 auto;

        &:hover {
          background: #0056b3;
        }
      }

      & > *:not(:first-child) {
        padding: 10px;
      }
    }
  }
}
