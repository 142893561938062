// Global Styles
// body {
//   margin: 0;
//   font-family: 'Arial', sans-serif;
//   color: #333;
//   line-height: 1.6;
// }
.trade {
  .content {
    // width: 100%;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.6;
  }
  
  .inner-content {
    // display: flex;
    // align-items:start;
    // flex-direction: column;
    text-align: left;
    width: 60%;
    // background-color: red;
    p, li{
      text-align: left;
      font-size: 1.2rem;
    }
    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
  
  .section-heading {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #000000;
  }
  
  .fade-up {
    opacity: 0;
    transform: translateY(30px);
    animation: fadeUp 0.8s ease-in forwards;
  }
  
  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  // Hero Section
  .hero {
    background: url('https://images.pexels.com/photos/4963437/pexels-photo-4963437.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1') no-repeat center center/cover;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
  
    .hero-overlay {
      background-color: rgba(0, 0, 0, 0.6);
      text-align: center;
      padding: 2rem;
  
      .hero-heading {
        font-size: 3rem;
        margin-bottom: 1rem;
      }
  
      .hero-text {
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
  
      .cta-button {
        background-color: #007bff;
        color: #fff;
        padding: 0.8rem 1.5rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  
  // About Section
  .intro,
  .about {
    padding: 3rem 1.5rem;
    text-align: center;
    color: white;
  
    .about-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 2rem;
  
      .about-content {
        max-width: 600px;
        text-align: left;
  
        h2 {
          font-size: 2rem;
          margin-bottom: 1rem;
        }
  
        p {
          font-size: 1rem;
          line-height: 1.6;
        }
      }
    }
  }
  
  // Benefits Section
  .benefits {
    background-color: #f9f9f9;
    padding: 3rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .benefits-list {
      list-style: disc;
      max-width: 600px;
      margin: 0 auto;
      padding-left: 1.5rem;
  
      li {
        margin-bottom: 1rem;
      }
    }
  }
  
  // Collaborations Section
  .collaborations {
    padding: 3rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  
    h2{
      color: white;
    }
    p{
      width: 60%;
      @media (max-width: 768px) {
        width: 90%;
        
      }
    }
  
    .opportunities-list {
      list-style: disc;
      max-width: 600px;
      // margin: 0 auto;
      padding-left: 1.5rem;
      margin-top: 1rem;
  
      li {
        margin-bottom: 1rem;
      }
    }
  }
  
  // Testimonials Section
  .testimonials {
    background-color: #f9f9f9;
    padding: 3rem 1.5rem;
  
    .testimonials-container {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;
  
      .testimonial-item {
        background-color: #fff;
        padding: 1.5rem;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        max-width: 400px;
        text-align: left;
  
        .testimonial-text {
          font-style: italic;
          margin-bottom: 1rem;
        }
  
        .testimonial-author {
          text-align: right;
          font-weight: bold;
        }
      }
    }
  }
  
  // Contact Section
  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 1.5rem;
    text-align: center;
    color:#fff;
    h2{
      color: white;
    }
  
    .contact-button {
      background-color: #28a745;
      color: #fff;
      padding: 0.8rem 1.5rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #218838;
      }
    }
  }
  
  // Responsive Design
  @media (max-width: 768px) {
    .hero .hero-overlay {
      padding: 1rem;
  
      .hero-heading {
        font-size: 2rem;
      }
  
      .hero-text {
        font-size: 1rem;
      }
    }
  
    .about-container {
      flex-direction: column;
      text-align: center;
  
      .about-content {
        text-align: center;
      }
    }
  
    .testimonials-container {
      flex-direction: column;
    }
  }
  }