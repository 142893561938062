.homepage {
  .fade-up {
    opacity: 0;
    transform: translateY(30px);
    animation: fadeUp 0.8s ease-in forwards;
  }

  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  color: azure;
  h1 {
    font-size: 3rem;
  }
  p,
  ul,
  button,
  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: azure;
    line-height: 1.6;
    font-size: 1.5rem;
    list-style: none;
    text-decoration: none;
  }

  li {
    margin-top: 10px;
  }
  .hero {
    height: 600px;
    background-image: url("../media/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: color;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      padding: 20px;
    }
  }

  .about {
    padding: 20px;

    .content {
      // background-color: red;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      min-height: 200px;
      a {
        text-decoration: underline;
      }
    }
  }

  .membership-benefits {
    padding: 20px;
    min-height: 450px;
    background-image: url("../media/bg2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: color;
    background-position: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .content {
      width: 70%;
      height: 80%;
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.511);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      @media (max-width: 786px) {
        width: 100%;
      }
      a {
        text-decoration: underline;
      }
    }

    .cta-button {
      padding: 10px;
      background-color: bisque;
      border-radius: 5px;
    }
  }

  .why {
    background-image: url("../media/scene.jpg");
  }

  .funding {
    background-image: url("../media/webinarH.jpg");
    background-position: top;
  }
}

// .homepage {
//   color: var(--ghost-white);
//   strong {
//     // color: var(--persian-red);
//     color: var(--persian-red);
//   }

//   .hero {
//     width: 100%;
//     height: 600px;
//     display: flex;
//     justify-content: space-between;
//     // padding: 30px 60px;
//     background: var(--lapiz);

//     .img-bg {
//       position: relative; // Parent needs to be relative for absolute positioning to work
//       // height: 100vh; // Full height for the hero section or adjust as needed
//       width: 100%;
//       background-color: black;
//       background-blend-mode: multiply;
//       .bg {
//         opacity: 0.35;
//         position: relative;
//         width: 100%;
//         height: 100%;
//         object-fit: cover; // Makes sure the image covers the container fully
//         position: absolute;
//         top: 0;
//         left: 0;
//         z-index: 0; // The image will be the background layer
//         object-position: 50% 7%;
//       }
//       .hero-content {
//         position: absolute;
//         max-width: 50%;
//         z-index: 1; // Ensures the content appears above the background image

//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         // background-color: #de542f;
//         left: 10%;
//         bottom: 10%;

//         h1 {
//           font-size: 2.5rem;
//           color: var(--ghost-white); // Dartmouth-green
//         }

//         p {
//           margin-top: 1rem;
//           font-size: 1.25rem;
//           color: var(--ghost-white);
//         }

//         .hero-buttons {
//           display: flex;
//           justify-content: space-evenly;
//           margin-top: 2rem;

//           .btn {
//             padding: 10px;
//             font-size: 1rem;
//             border-radius: 0.25rem;
//             text-decoration: none;
//             transition: background-color 0.3s;

//             &.primary-btn {
//               background-color: var(--lapiz); // Cinnabar
//               color: white;

//               &:hover {
//                 background-color: #012132; // Tangerine
//               }
//             }

//             &.secondary-btn {
//               background-color: var(--persian-red); // Picton-blue
//               color: white;

//               &:hover {
//                 background-color: #501515; // Verdigris
//               }
//             }
//           }

//           @media (max-width: 768px) {
//           }
//         }
//       }
//     }

//     .hero-image {
//       max-width: 50%;

//       img {
//         object-fit: cover;
//         width: 100%;
//         height: 100%;
//         border-radius: 1rem;
//       }
//     }

//     @media (max-width: 768px) {
//       flex-direction: column;

//       .hero-content {
//         max-width: 100%;
//         text-align: center;
//       }

//       .hero-image {
//         margin-top: 2rem;
//         max-width: 100%;
//       }
//     }
//   }

//   .abs-bg {
//     position: relative;
//     width: 100%;
//     height: 700px;
//     background-color: black;
//     // margin: 0 auto;
//     .bg {
//       opacity: 0.65;
//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//       object-position: 50% 15%;
//     }

//     .content {
//       background-color: rgba(0, 0, 0, 0.344);
//       padding:10px;
//       position: absolute;
//       bottom: 25%;
//       left: 8%;
//       // transform: translate(-50%, -50%);
//     }
//     h2 {
//       font-size: 2.7rem;
//       color: var(--ghost-white);
//       margin-bottom: 20px;
//     }

//     p {
//       text-align: left;
//       font-size: 1.4rem;
//       max-width: 800px;
//       margin: 0 auto;
//       color: var(--ghost-white);
//       // color: var(--smokey-black);
//     }
//   }
//   .about-anzeg {
//     background-color: var(--lapiz);
//     padding: 60px 20px;
//     text-align: center;

//     h2 {
//       font-size: 2.5rem;
//       color: var(--ghost-white);
//       margin-bottom: 20px;
//     }

//     p {
//       text-align: left;
//       font-size: 1.2rem;
//       max-width: 800px;
//       margin: 0 auto;
//       color: var(--ghost-white);
//       // color: var(--smokey-black);
//     }
//   }

//   .membership-benefits {
//     position: relative;
//     background-color: black;
//     text-align: center;
//     width: 100%;
//     height: 600px;
//     // margin: 0 auto;
//     .bg {
//       opacity: 0.35;
//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//     }

//     .content {
//       position: absolute;
//       bottom: 15%;
//       left: 20%;

//       .cta-button {
//         background-color: var(--persian-red);
//         color: var(--smokey-black);
//         padding: 10px 20px;
//         border: none;
//         font-size: 1.2rem;
//         cursor: pointer;
//         transition: background-color 0.3s ease;
//         border-radius: 0.25rem;

//         &:hover {
//           background-color: #012132;
//         }
//       }

//       h2 {
//         font-size: 2.5rem;
//         // color: var(--lapiz);
//         color: var(--ghost-white);

//         // margin-bottom: 20px;
//       }

//       ul {
//         list-style: none;
//         padding: 0;
//         // margin-bottom: 30px;
//         margin-left: 25px;

//         li {
//           text-align: left;
//           font-size: 1.2rem;
//           margin: 15px 0;
//           // color: var(--smokey-black);
//           color: var(--ghost-white);
//         }
//       }
//     }
//   }

//   .upcoming-events {
//     background-color: var(--lapiz);
//     color: var(--ghost-white);
//     padding: 60px 20px;
//     text-align: center;

//     h2 {
//       font-size: 2.5rem;
//       margin-bottom: 20px;
//     }

//     p {
//       font-size: 1.2rem;
//       margin-bottom: 30px;
//     }

//     .cta-button {
//       background-color: var(--persian-red);
//       color: var(--smokey-black);
//       padding: 10px 20px;
//       border: none;
//       font-size: 1.2rem;
//       cursor: pointer;
//       transition: background-color 0.3s ease;
//       border-radius: 0.25rem;

//       &:hover {
//         background-color: #501515;
//       }
//     }
//   }
// }
