.membership-page {
  .fade-up {
    opacity: 0;
    transform: translateY(30px);
    animation: fadeUp 0.8s ease-in forwards;
  }

  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  background-color: var(--ghost-white);
  // padding: 40px 20px;

  .membership-header {
    text-align: left;
    // padding: 40px;
    background: black;
    color: var(--ghost-white);
    position: relative;
    height: 600px;

    .bg{
      opacity: 0.35;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: cover;
    }

    .content{
      position: absolute;
      bottom: 10%;
      left: 10%;
      h1 {
        text-align: center;
        font-size: 2.5rem;
        margin-bottom: 20px;
      }
  
      p {
        font-size: 1.2rem;
        max-width: 800px;
        margin: 10px auto;
        line-height: 1.6;
      }
    }

  }

  .membership-plans {
    display: flex;
    justify-content: space-around;
    // margin: 40px 0;
    padding: 25px;
    background-color: var(--lapiz);


    .plan {
      background-color: var(--ghost-white);
      border: 1px solid var(--smokey-black);
      border-radius: 10px;
      padding: 30px;
      width: 30%;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      text-align: left;
      transition: transform 0.3s ease;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:hover {
        transform: translateY(-10px);
      }

      h2 {
        font-size: 1.8rem;
        color: var(--lapiz);
        margin-bottom: 20px;
      }

      .price {
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--lapiz);
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 30px;

        li {
          font-size: 1.2rem;
          margin: 10px 0;
          color: var(--smokey-black);
        }
      }

      .join-btn {
        background-color: var(--persian-red);
        color: var(--smokey-black);
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        transition: background-color 0.3s ease;
        border-radius: 15px;
        // margin: 0 auto;

        &:hover {
          background-color: #631919;
        }
      }
    }
    
    @media (max-width: 860px){
      flex-direction: column;
      padding: 10px;
      .plan {
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }
  .cta-banner {
    background-color: var(--lapiz);
    color: var(--ghost-white);
    text-align: left;
    padding: 2rem;
    margin-bottom: 3rem;


    h2 {
      margin-bottom: 1rem;
    }

    .cta-btn {
      background-color: var(--persian-red);
      color: var(--ghost-white);
      padding: 0.75rem 2rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .success-story {
    background-color: var(--ghost-white);
    padding: 2rem;
    border-bottom: 1px solid var(--lapiz);
    margin-bottom: 3rem;

    h2 {
      color: var(--lapiz);
      margin-bottom: 1rem;
    }

    p {
      line-height: 1.6;
    }
  }

  .testimonials {
    text-align: center;
    margin-bottom: 3rem;

    h2 {
      margin-bottom: 2rem;
      color: var(--lapiz);
    }

    .testimonial {
      background-color: var(--lapiz);
      color: var(--ghost-white);
      padding: 1.5rem;
      margin: 1rem 0;
      
      p {
        margin-bottom: 1rem;
        font-style: italic;
      }

      span {
        font-weight: bold;
      }
    }
  }

  .faqs {
    padding: 2rem;
    background-color: var(--ghost-white);

    h2 {
      margin-bottom: 1.5rem;
      color: var(--lapiz);
    }

    .faq-item {
      margin-bottom: 2rem;
      
      h3 {
        margin-bottom: 0.5rem;
        color: var(--lapiz);
      }
    }
  }

  
}
