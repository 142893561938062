* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Oswald", sans-serif; */
  font-family: "Playfair Display", serif;
}

:root {
  --persian-red: #f4e04d; /*ui secondary*/
  --lapiz:#008ad5; 
  --ghost-white: #F7F7FF;
  --smokey-black: #070600;
}
body {
  /* background-color: var(--ghost-white)  */
  background-color: var(--lapiz);

}
.caveat {
  /* font-family: "Caveat", cursive; */
  font-family: "Playfair Display", serif;
}