.footer {
  background-color: #00304a;
  color: #F7F7FF;
  padding: 40px 20px;
  font-family: "Arial", sans-serif;

  .footer-content {
    // background-color: red;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    // align-items: center;
    gap: 20px;

    .footer-column {
      // flex: 1;
      min-width: 200px;

      h3 {
        font-size: 18px;
        margin-bottom: 10px;
        color: #f0f0f0;
      }

      p {
        font-size: 14px;
        margin: 5px 0;
      }

      .footer-logo {
        max-width: 150px;
        margin-bottom: 10px;
      }
    }

    .footer-social {
      .social-links {
        display: flex;
        gap: 15px;

        a {
          color: #ffffff;
          font-size: 20px;
          transition: color 0.3s;

          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #bbbbbb;
  }
}

@media (max-width: 768px) {
  .footer {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }

    .footer-column {
      text-align: center;
    }

    .footer-social {
      .social-links {
        justify-content: center;
      }
    }
  }
}
